import React, { Suspense, Fragment, useContext, useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { routesKms } from "./routes";
import { createBrowserHistory } from "history";
import PageLoading from "../src/component/PageLoading";
import AuthGuard from "./component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import AuthContext from "./context/Auth";
import { createCustomTheme } from "./theme";
function App() {
  const theme = createCustomTheme();
  const history = createBrowserHistory();
  const auth = useContext(AuthContext);

  function RenderRoutes(props) {
    return (
      <Suspense fallback={<PageLoading />}>
        <AuthGuard />
        <Switch>
          {props.data.map((route, i) => {
            const Component = route.component;
            const Guard = route.guard ? AuthGuard : Fragment;

            const Layout = route.layout || Fragment;
            return (
              <Route
                exact={route.exact}
                key={i}
                path={route.path}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routesKms ? (
                        <RenderRoutes data={route.routesKms} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <AuthContext>
        <Router history={history}>
          <RenderRoutes data={routesKms} />
        </Router>
      </AuthContext>
    </ThemeProvider>
  );
}

export default App;
