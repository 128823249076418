import React, { useContext } from "react";
import { makeStyles, Box, Container, Typography } from "@material-ui/core";
import LanguageComponent from "../component/LanguageComponant";
import EmailIcon from "@material-ui/icons/Email";
import { localeFunction } from "../utils";
import CallIcon from "@material-ui/icons/Call";
import { AuthContext } from "../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "40px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 425px)": {
      height: "auto",
    },
    "& svg": {
      fontSize: "18px",
    },
    "& a": {
      color: "#fff",
      textDecoration: "none",
      fontFamily: "system-ui",
      fontSize: "16px",
      fontWeight: "400",
      "@media (max-width: 576px)": {
        fontSize: "13px",
      },
    },
  },

  topbackground: {
    backgroundColor: "rgb(8 9 11)",
    height: "inherit",
    "@media(max-width:576px)": {
      paddingBottom: "17px",
    },

    "@media(max-width:425px)": {
      padding: "5px 0px",
      height: "initial",
    },
    "@media(max-width:769px)": {
      padding: "5px 0px",
      height: "initial",
    },
  },
  lefttopbartext: {
    display: "flex",
    alignItems: "center",
    color: "#f4e757",
    gap: "13px",
    "& h6": {
      color: "#fff",
      display: "flex",
      alignItems: "center",
      "@media(max-width:769px)": {
        fontSize: "12px",
      },
      "& span": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },
    },

    "& p": {
      display: "flex",
      alignItems: "center",
      "& a": {
        "&:hover": {
          transition: "0.5s ease-in-out",
          color: "rgb(255, 205, 30)",
        },
      },
    },
    "@media(max-width:768px)": {
      display: "block",
      lineHeight: "0px",
    },
    "@media(max-width:425px)": {
      display: "block",
      lineHeight: "0px !important",
    },
    "@media(max-width:375px)": {
      display: "block",
    },
    "@media(max-width:320px)": {
      display: "block",
    },
  },
}));
const TopbarTop = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <Box className={classes.topbackground} width={"100%"}>
      <Container className="topContainer">
        <Box className={classes.mainBox}>
          <Box className={classes.lefttopbartext}>
            <Typography variant="h6">
              {localeFunction("IT_Helpdesk")} : &nbsp;
              <CallIcon style={{ fontSize: "15px", color: "#f4e757" }} />
              &nbsp; 011-24600-668
            </Typography>

            <Typography variant="body1">
              <EmailIcon style={{ color: "#f4e757" }} />
              &nbsp;
              <Typography
                variant="link"
                component="a"
                href="mailto:aicho@aicofindia.com"
              >
                itservicedesk@aicofindia.com
              </Typography>
            </Typography>
          </Box>

          <LanguageComponent />
        </Box>
      </Container>
    </Box>
  );
};

export default TopbarTop;
