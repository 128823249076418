import React from "react";
import { Box, makeStyles, IconButton, Typography } from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const useStyles = makeStyles((theme) => ({
  mainAnnoucemnetZeroStatus: {
    borderRadius: "0px",
    padding: "4px 16px",
    boxShadow: "none",
    marginTop: "5px",
  },
  childZeroStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "auto",
  },
  underlineText: {
    "@media(max-width:1280px)": {
      fontSize: "14px",
    },
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
  },
  annoucementText: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    "& button": {
      width: "35px",
      height: "35px",
      color: "#FFF",
      background: "#06485b",
    },
    "& h3": {
      lineHeight: "23px",
      fontWeight: 400,
      fontFamily: "system-ui",
      "@media(max-width:1280px)": {
        fontSize: "16px",
      },
    },
  },
}));

const AnnoucemnetZeroStatus = ({ data, waterMrkFunctionApi }) => {
  const classes = useStyles();
  return (
    <Box elevation={0} className={classes.mainAnnoucemnetZeroStatus}>
      <Box className={classes.childZeroStatus}>
        <Box className={classes.underlineText}>
          <Box className={classes.annoucementText}>
            <IconButton>
              <LibraryBooksIcon style={{ fontSize: "18px" }} />
            </IconButton>

            <Typography
              variant="h3"
              onClick={() => waterMrkFunctionApi("", data?.id)}
            >
              {data?.title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AnnoucemnetZeroStatus;
