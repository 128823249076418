import React from "react";
import { Avatar, Box, makeStyles } from "@material-ui/core";
import MenuProfileComponent from "../component/MenuProfileComponent";
const useStyles = makeStyles((theme) => ({
  profileImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "10px",
    "& avatar": {
      width: "55px",
      height: "55px",
    },
    "@media(max-width:615px)": {
      Padding: "0 0 15px 0",
      background: "#fff",
    },
  },
}));
const ProfileIamge = (props) => {
  const { image } = props;
  const classes = useStyles();
  return (
    <Box className={classes.profileImage}>
      <Avatar src={image} alt="img" />

      <MenuProfileComponent />
    </Box>
  );
};

export default ProfileIamge;
