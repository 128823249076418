import React from "react";

import {
  Box,
  makeStyles,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonAccoDetails from "./CommonAccoDetails";

const useStyles = makeStyles((theme) => ({
  mainAnnoucemnetZeroStatus: {
    borderRadius: "0px",
    padding: "4px 16px",
    boxShadow: "none",
    marginTop: "5px",
  },
  childZeroStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "auto",
  },
  underlineText: {
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
    "@media(max-width:1280px)": {
      fontSize: "14px",
    },

    "& .oneTitleBox": {
      cursor: "auto !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px",
      "& h3": {
        lineHeight: "23px",
        fontWeight: 400,
        fontFamily: "system-ui",
        cursor: "pointer",
        fontSize: "16px",
      },
    },
  },
}));

const AnnoucemnetOneStatus = ({
  data,
  waterMrkFunctionApi,
  index,
  handleChange,
  expanded,
}) => {
  const classes = useStyles();
  return (
    <Accordion
      expanded={expanded === index}
      onChange={handleChange(index)}
      style={{
        background: "#FFF",
        boxShadow: "none",
      }}
    >
      <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
        <Box display={"flex"} alignItems={"center"} width={"100%"}>
          <Box className={classes.childZeroStatus}>
            <Box className={classes.underlineText}>
              <Box className="oneTitleBox">
                <IconButton
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "#FFF",
                    background: "#06485b",
                  }}
                >
                  <LibraryBooksIcon style={{ fontSize: "18px" }} />
                </IconButton>

                <Typography variant="h3">{data?.title}</Typography>
              </Box>
            </Box>
          </Box>
          <IconButton>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {data &&
          data?.list?.map((items, index) => {
            return (
              <>
                <CommonAccoDetails
                  waterMrkFunctionApi={waterMrkFunctionApi}
                  items={items}
                  index={index}
                />
              </>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnoucemnetOneStatus;
