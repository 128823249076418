import React, { useContext, useState } from "react";
import {
  makeStyles,
  IconButton,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import TopbarTop from "../../component/TopbarTop";
import ProfileIamge from "../../component/ProfileIamge";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "../../component/Logo";
import MenuIcon from "@material-ui/icons/Menu";
import Navbar from "../../view/pages/home/Top_navbar";
import MobileDrawer from "../../component/MobileDrawer";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  mainTopbar: {
    background: "#23262f",
    position: "fixed",
    left: "0px",
    right: "0px",
    top: "0px",
    zIndex: 999,
  },
  mainLogoBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  comapnyName: {
    "& p": {
      color: "#FFF",
      fontSize: "14px",
    },
    "& h6": {
      color: "#FFF",
      fontWeight: 400,
    },
    "@media(max-width:600px)": {
      display: "none",
    },
  },
  logo: {
    maxWidth: "130px",
    "& .logoImg": {
      maxWidth: "150px",
    },
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      textAlign: "left",
    },
  },
  userProfile: {
    "@media(max-width:1024px)": {
      display: "none",
    },
  },
  navbarContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 0px",
  },
  MobilemenuIcon: {
    display: "none",
    "@media(max-width:1024px)": {
      display: "block",
    },
    "& .icon": {
      color: "#FFF",
      fontSize: "30px !important",
    },
  },
  navbarClass: {
    display: "block",

    "@media(max-width:615px)": {
      display: "none",
    },
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const { profileData } = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const closeFunction = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.mainTopbar}>
      <TopbarTop />
      <Box className={classes.navbar}>
        <Container>
          <Box className={classes.navbarContent}>
            <Box className={classes.mainLogoBox}>
              <Box className={classes.logo}>
                <NavLink
                  to="/"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  <Logo className="logoImg" />
                </NavLink>
              </Box>

              <Box className={classes.comapnyName}>
                <Typography variant="body1">
                  भारतीय कृषि बीमा कंपनी
                  <span
                    style={{
                      fontSize: "15px",
                      paddingTop: "3px",
                      paddingLeft: "2px",
                    }}
                  >
                    ®
                  </span>
                </Typography>
                <Typography variant="h6">
                  Agriculture Insurance Company of India Limited
                </Typography>
              </Box>
            </Box>
            <Box className={classes.userProfile}>
              <ProfileIamge image={profileData?.image} />
            </Box>

            <Box className={classes.MobilemenuIcon}>
              <IconButton onClick={() => setOpen(true)}>
                <MenuIcon className="icon" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.navbarClass}>
        <Navbar />
      </Box>
      <MobileDrawer
        open={open}
        setOpen={setOpen}
        closeFunction={closeFunction}
      />
    </Box>
  );
};

export default TopBar;
