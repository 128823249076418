import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainDetailsClass: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "auto !important",
  },
  underlineText: {
    color: "#000",
    textDecoration: "none",
    transition: "0.5s ease-in-out",
    "& :hover": {
      textDecoration: "underline",
      color: "#0192be",
    },
    "@media(max-width:1280px)": {
      fontSize: "14px",
    },

    "& .oneTitleBox": {
      cursor: "auto !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px",
      "& h3": {
        lineHeight: "23px",
        fontWeight: 400,
        fontFamily: "system-ui",
        cursor: "pointer",
        fontSize: "14px",
      },
    },
  },
}));

const CommonAccoDetails = ({ items, index, waterMrkFunctionApi }) => {
  const classes = useStyles();
  return (
    <Box key={index} className={classes.mainDetailsClass}>
      <Box className={classes.underlineText}>
        <Box p={"0 75px 10px"} display={"flex"} alignItems={"center"}>
          <Typography
            variant="h3"
            style={{
              lineHeight: "23px",
              fontWeight: 400,
              fontFamily: "system-ui",
              cursor: "pointer",
              fontSize: "15px",
            }}
            onClick={() => waterMrkFunctionApi("", items?.id)}
          >
            {items?.title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CommonAccoDetails;
