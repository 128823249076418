import React, { useContext } from "react";
import { makeStyles, Box, Container } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { AuthContext } from "../context/Auth";
import BackDropSpinner from "../componentkms/BackDropSpinner";
import AnnoucemnetZeroStatus from "./AnnoucemnetZeroStatus";
import AnnoucemnetOneStatus from "./AnnoucemnetOneStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: "30px",
  },
}));

export default function Allannoucement({
  annoucement,
  setPage,
  page,
  _count,
  page1,
  setPage1,
  _count1,
  searchQuery,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const auth = useContext(AuthContext);
  const waterMrkFunctionApi = auth?.addWaterMarkFunc;
  const spinnerLoader = auth?.isLoading;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = createMuiTheme({
    overrides: {
      MuiTabs: {
        flexContainer: {
          justifyContent: "start !important",
        },
      },
      MuiPaginationItem: {
        page: {
          "&.Mui-selected": {
            color: "#fff",
            backgroundColor: "#0192be",
          },
        },
      },
      MuiAccordionSummary: {
        content: {
          display: "initial",
          margin: "0px 0px !important",
          padding: "5px 0px !important",
        },
      },
      MuiTypography: {
        body1: {
          fontSize: "16px",
          fontWeight: "500",
          color: "#000",
          // textAlign: "justify",
          fontFamily: "system-ui",
        },
        h3: {
          fontFamily: "system-ui",
          fontSize: "16px",
          lineHeight: "40px",
          fontWeight: "500",
        },
      },
      MuiAccordionDetails: {
        root: {
          display: "initial",
          padding: "0 0 10px 0",
        },
      },
      MuiAccordion: {
        root: {
          "&::before": {
            background: "none !important",
          },
        },
      },
    },
  });

  return (
    <div className={classes.root}>
      {spinnerLoader ? (
        <BackDropSpinner spinnerLoader={spinnerLoader} />
      ) : (
        <Container>
          <ThemeProvider theme={theme}>
            {searchQuery !== "" ? (
              <Box>
                {annoucement &&
                  annoucement?.map((data, index) => {
                    if (data?.status === "0") {
                      return (
                        <>
                          <AnnoucemnetZeroStatus
                            data={data}
                            waterMrkFunctionApi={waterMrkFunctionApi}
                          />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <AnnoucemnetOneStatus
                            data={data}
                            waterMrkFunctionApi={waterMrkFunctionApi}
                            index={index}
                            handleChange={handleChange}
                            expanded={expanded}
                          />
                        </>
                      );
                    }
                  })}
                {annoucement?.length > 0 && (
                  <Box display={"flex"} justifyContent={"center"} mt={6}>
                    <Pagination
                      count={_count}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                {annoucement &&
                  annoucement?.map((data, index) => {
                    if (data?.status == "0") {
                      return (
                        <>
                          <AnnoucemnetZeroStatus
                            data={data}
                            waterMrkFunctionApi={waterMrkFunctionApi}
                          />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <AnnoucemnetOneStatus
                            data={data}
                            waterMrkFunctionApi={waterMrkFunctionApi}
                            index={index}
                            handleChange={handleChange}
                            expanded={expanded}
                          />
                        </>
                      );
                    }
                  })}
                {annoucement?.length > 0 && (
                  <Box display={"flex"} justifyContent={"center"} mt={6}>
                    <Pagination
                      count={_count1}
                      page={page1}
                      onChange={(e, v) => setPage1(v)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </ThemeProvider>
        </Container>
      )}
    </div>
  );
}
