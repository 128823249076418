import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Avatar,
  Typography,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../component/Logo";
import TopbarTop from "../../component/TopbarTop";
import MenuProfileComponent from "../../component/MenuProfileComponent";
import ResponsiveMenu from "../../component/ResponsiveMenu";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    padding: "5px 0px 4px 0px",
    justifyContent: "space-between",
    height: "67px !important",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "76px !important",
    },
    "@media (max-width: 1024px)": {
      display: "flex",
    },
  },

  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
    "@media (max-width: 998px)": {
      top: "9px",
    },
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },

  containerHeight: {
    height: "100%",
    transform: "translateY(0px)",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "6px 0px",

    "@media(max-width:998px)": {
      padding: "6px 0px",
      margin: "0px 25px",
    },
    "@media(max-width:1024px)": {
      padding: "6px 0px",
      margin: "0px 32px 0px 25px",
    },
    "@media(max-width:576px)": {
      padding: "6px 0px",
      margin: "0px 20px",
    },
  },

  dropdown: {
    "& .dropdown-content": {
      display: "none",
    },
    "&:hover": {
      "& .dropdown-content": {
        display: "block",
      },
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    "& ul": {
      display: "flex",
      listStyle: "none",
      width: "100%",
    },
    "& li": {
      display: "inline-block",
      padding: "20px 0 20px",
      verticalAlign: "middle",
      "& .dropdown-content": {
        display: "none",
      },
      "&:hover": {
        "& .dropdown-content": {
          display: "block",
        },
      },
    },

    "& a:hover, & a:focus, & a:active": {
      color: "rgb(31, 99, 6)",
      textDecoration: "none",
    },
    "& a": {
      fontFamily: "system-ui",
      textDecoration: "none",
      transition: "color 0.1s, background-color 0.1s",
      position: "relative",
      display: "block",
      padding: "16px 0",
      margin: "0 12px",
      letterSpacing: "1px",
      fontSize: "17px",
      lineHeight: "16px",
      fontWeight: 500,
      // textTransform: "uppercase",
      color: "#000",
      textAlign: "center",
    },
    "& a::before": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 3,
      left: 0,
      height: 3,
      width: "100%",
      backgroundColor: "#f4e757",
      transformOrigin: "right top",
      transform: "scale(0, 1)",
      transition: "color 0.1s, transform 0.2s ease-out",
    },
    "& a:active::before": {
      backgroundColor: "#000",
    },
    "& a:hover::before, & a:focus::before": {
      transformOrigin: "left top",
      transform: "scale(1, 1)",
    },
  },
  appBarrClass: {
    "@media(max-width:1220px)": {
      // paddingBottom: "15px 0px",
    },
  },
  GridClass: {
    padding: "0 0 0 120px",
    "@media(max-width:1280px)": {
      padding: "0 0 0 30px",
    },
  },
  harderClass: {
    left: 0,
    width: "100%",
    padding: 0,
    zIndex: "999",
    position: "fixed",
    background: "#23262f",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    marginTop: "0px",
    top: "0",
    transform: "translateY(0px)",

    transition: "0.6s",
  },

  SocialBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",

    "& a": {
      color: "#FFFFFF",
      textDecoration: "none",
    },
  },
  unorderlist: {
    display: "flex",
    gap: "23px",
    alignItems: "center",
    margin: "45px 0px",
  },
  listItem: {
    background: "rgb(31, 99, 6)",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    listStyle: "none",
    transition: "0.3s ease-in-out",
    "&:hover": {
      color: "#FFFFFF",
      background: "transparent",
      cursor: "pointer",
    },
    "& .icon": {
      color: "#FFFFFF",
    },
  },
  topsectionnavbar: {
    padding: "27px 0px 0px 35px",
  },
  comapnyBox: {
    paddingLeft: "15px",
    width: "980px",
    "@media(max-width:1280px)": {
      width: "1200px",
    },
    "@media(max-width:425px)": {
      margin: "10px 0px 4px 0px",
      paddingLeft: "0px",
    },
    "@media(max-width:768px)": {
      margin: "10px 0px 4px 0px",
      paddingLeft: "0px",
    },
  },
  comapnyName: {
    "& h5": {
      color: "#ffe3aa",
      fontSize: "16px",
      fontWeight: 400,
      "@media(max-width:375px)": {
        fontSize: "14px",
      },
    },
    "& p": {
      color: "#ffe3aa",
      fontSize: "14px",
      fontWeight: 400,
      "@media(max-width:375px)": {
        fontSize: "12px",
      },
    },
  },
  maintopbarcolor: {
    background: "#23262f",
  },
  appBarrClass: {
    paddingRight: "initial !important",
  },
  logoWidth: {
    maxWidth: "130px",
    "@media(max-width:375px)": {
      maxWidth: "100px",
    },
    "@media(max-width:768px)": {
      maxWidth: "115px",
    },
  },
  paper: {
    width: "240px",
    background: "#23262f",
  },
  mobileprofile: {
    padding: "20px 0px 20px 15px",
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },
  mainLogo: {
    "@media(max-width:768px)": {
      display: "block !important",
    },
    "@media(max-width:425px)": {
      display: "block !important",
    },
    "@media(max-width:1024px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  logoTopbar: {
    "@media(max-width:768px)": {
      display: "flex",

      alignItems: "center",
    },
  },
}));

export default function Header({ type }) {
  const classes = useStyles();
  const { toolbar, drawericon, mainHeader } = useStyles();
  const [openmenu, setMenu] = useState("Home");

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const reloadFuction = () => {
    return window.location.reload();
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const MenuValue = [];
  const displayDesktop = () => {
    return (
      <Box className={classes.maintopbarcolor}>
        <Container>
          <Toolbar className={toolbar}>
            {femmecubatorLogo}
            <Grid
              className={classes.GridClass}
              container
              item
              direction="row"
              alignItems="center"
            >
              {getMenuButtons()}
            </Grid>
          </Toolbar>
        </Container>
      </Box>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          <Box className={classes.mobileprofile}>
            <Avatar
              src="/logo/placeholder.webp"
              alt="image"
              style={{ width: "60px", height: "60px" }}
            />
            <Box>
              <MenuProfileComponent />
            </Box>
          </Box>
        </Drawer>

        <div className={classes.logoTopbar}>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#FFF", fontSize: "35px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <>
      <Box className={classes.logoWidth}>
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            textAlign: "left",
          }}
        >
          <Logo className="logoImg" onClick={reloadFuction} />
        </Link>
      </Box>
      <Box className={classes.comapnyBox}>
        <Box className={classes.comapnyName}>
          <Typography variant="body1">भारतीय कृषि बीमा कंपनी</Typography>
          <Typography variant="h5">
            Agriculture Insurance Company of India Limited
          </Typography>
        </Box>
      </Box>
      {/* </Box> */}
    </>
  );

  const getMenuButtons = (value) => {
    return (
      <>
        {MenuValue?.map((data, index) => {
          return (
            <>
              <ResponsiveMenu
                CompanyTitle={data}
                data={data}
                index={index}
                value={value}
                openmenu={openmenu}
                setMenu={setMenu}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {type ? (
        <AppBar
          elevation={0}
          style={{
            background: "#23262f",
            border: "none",

            transition: "0.3s ease-in-out",
          }}
          className={classes.appBarrClass}
        >
          <TopbarTop />
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      ) : (
        <AppBar
          elevation={0}
          style={{
            background: "#23262f",
            border: "none",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
            transition: "0.3s ease-in-out",
          }}
          className={classes.appBarrClass}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      )}
    </>
  );
}
